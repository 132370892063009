@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Rubik", sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #f6f0e1;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  h1 {
    @apply font-rubik 1xl:text-[64px] font-black 1xl:leading-[75.84px] md:text-[#2D2C2A] text-[24px] leading-[30px] text-[#2E2E2E] sm:text-[34px] sm:leading-[40px] lg:text-[44px] lg:leading-[52px] xl:text-[50px];
  }
  h2 {
    @apply font-rubik 1xl:text-[48px] font-black 1xl:leading-[56.88px] text-[20px] leading-[23.7px] sm:text-[28px] sm:leading-[30px] md:text-2xl lg:text-[32px] lg:leading-[30px] xl:leading-[36px];
  }
  h3 {
    @apply font-rubik 1xl:text-[40px] font-black 1xl:leading-[47.4px] text-[20px] leading-[23.7px] sm:text-[24px] sm:leading-[28px] lg:text-[30px];
  }
  h4 {
    @apply font-rubik 1xl:text-[24px] font-semibold 1xl:leading-[28.44px] text-[16px] leading-[18.96px] sm:text-[18px] sm:leading-[22px] md:text-[16px] md:leading-[18.96px] lg:text-lg xl:text-[20px] xl:leading-[24px];
  }
  h5 {
    @apply font-rubik 1xl:text-[20px] 1xl:leading-[23.7px] text-[14px] leading-[16.59px] sm:text-[16px] sm:leading-[20px] lg:text-[16px] xl:text-[18px];
  }
  h6 {
    @apply font-rubik xl:text-[16px] xl:leading-[18.96px] text-[14px] leading-[16.59px] lg:text-[15px];
  }
  p {
    @apply font-rubik text-[13px] font-bold leading-[15.41px];
  }
  a {
    @apply font-rubik xl:text-[16px] font-normal xl:leading-[18.96px] m:text-[14px] m:leading-[15.41px] lg:text-[15px];
  }
  li {
    @apply font-rubik xl:text-[16px] xl:leading-[18.96px] text-[14px] leading-[16.59px] sm:text-[15px] sm:leading-[17px];
  }

  .text-s-3 {
    -webkit-text-stroke: 3px #2d2c2a;
  }

  .text-s-3-xs {
    -webkit-text-stroke: 1px #2d2c2a;
  }

  .text-s-2 {
    -webkit-text-stroke: 2px #2d2c2a;
  }

  .text-s-4 {
    -webkit-text-stroke: 4px #2d2c2a;
  }

  .text-s-4-xs {
    -webkit-text-stroke: 1px #2E2E2E;
  }

  .text-price-2 {
    -webkit-text-stroke: 2px #2e2e2e;
  }

  .text-price-2-xs {
    -webkit-text-stroke: 1px #2e2e2e;
  }

  .text-white-2 {
    -webkit-text-stroke: 2px #f8efe8;
  }
  .text-white-2-xs {
    -webkit-text-stroke: 1px #f8efe8;
  }

  .text-white-1 {
    -webkit-text-stroke: 1px #ffffff;
  }
}
